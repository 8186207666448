import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import HTTPService from "../../utils/makerequest";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
import { IPageProps } from "../../interfaces/page-data";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { ICompany } from "../../interfaces/ICompany";



const AddUser: React.FunctionComponent<IPageProps> = (props) => {
  const [companyData, setCompanyData] = useState<ICompany[]>();

  useEffect(() => {
    AllCompany();
  }, []);

  const AllCompany = () => {
    HTTPService.GET(`/Company/AllCompanyNames`)
      .then((response) => {
        if (!response.data) {
          return;
        }
        let tmp: ICompany[] = JSON.parse(response.data);
        console.log(tmp);
        tmp.map((elem, index) => {
          elem['key'] = index;
        });
        console.log(tmp)
        setCompanyData(tmp);

      })
      .catch((error) => {
        console.error('Grup adları çekme hatası:', error);
      });
  }
  const Register = (value: any) => {
    let companyIdToUse;
    if (props.loginUserDetails.role.includes("AltisAdmin")) {
      companyIdToUse = value.selectedCompany;
    } else {

      companyIdToUse = props.loginUserDetails.companyId;
    }
    const newUser = {
      companyId: companyIdToUse,
      email: value.email,
      firstName: value.firstname,
      lastName: value.lastname,
      role: value.role,
      //password: value.password,
      //permissionGroupId: selectedGroupId,
    };
    HTTPService.POST(`/Account/register`, newUser)
      .then((response) => {
        if (response.status === 200) {
          toast.success('User Add Successfully')
          window.location.replace('/admin/user-settings')
        } else {
          console.error("Kullanıcı kaydı başarısız.");
          toast.error(response.data)
        }
      })
      .catch((error) => {
        console.error("Kullanıcı kaydı başarısız: ", error);
      });
  };
  const handleCancel = () => {
    window.location.replace('/admin/user-settings/');
  };

  return (
    <Card style={{ padding: '50px' }}>
      <h3>Register User</h3>
      <Form layout='vertical' onFinish={Register}>
      {/* {props.loginUserDetails.role.includes("AltisAdmin") ? (
          <Form.Item
            name="selectedCompany"
            rules={[
              {
                required: true,
                message: 'Please select a company!',
              },
            ]}
          >
            <Select
              showSearch placeholder='Company Name'
              onChange={(value) => AllCompany()}
            >
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
              ) : null} */}
        
        <Form.Item
          label='Email'
          name='email'
          rules={[
            {
              required: true,
              message: 'Please input your Email!',
              whitespace: true,
            },
            {
              pattern: /^[^\s]+$/,
              message: 'Email cannot contain spaces!',
            },
          ]}
        >
          <Input
            prefix={<MailOutlined />}
            placeholder='Email'
            style={{ borderRadius: 0 }}
          />
        </Form.Item>
        <Form.Item
          label='First Name'
          name='firstname'
          rules={[
            {
              required: true,
              message: 'Please input your Firstname!',
              whitespace: true,
            },
            {
              pattern: /^[^\s]+$/,
              message: 'Firstname cannot contain spaces!',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder='Firstname'
            style={{ borderRadius: 0 }}
          />
        </Form.Item>
        <Form.Item
          label='Last Name'
          name='lastname'
          rules={[
            {
              required: true,
              message: 'Please input your Lastname!',
              whitespace: true,
            },
            {
              pattern: /^[^\s]+$/,
              message: 'Lastname cannot contain spaces!',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder='LastName'
            style={{ borderRadius: 0 }}
          />
        </Form.Item>

        <Form.Item
          label='Role'
          name='role'
          rules={[
            {
              required: true,
              message: 'Please select your Role!',
            },
          ]}>
          <Select showSearch placeholder='Role'>
          {props.loginUserDetails.role.includes("AltisAdmin") ? (
            <Select.Option value={0}>AltisAdmin</Select.Option>
            ) : null}
            <Select.Option value={1}>Admin</Select.Option>
            {/* <Select.Option value={2}>User</Select.Option> */}
          </Select>
        </Form.Item>
        {/* <Form.Item
          name='GroupsName'
          rules={[{ required: true, message: 'Please input your Group Name!' }]}
        >
          <Select
            placeholder='Select a Group Name'
            allowClear // Seçimi temizlemeye izin vermek için
            className='rounded-select'
            style={{ borderRadius: '20px !important' }}
            onChange={(value) => setSelectedGroupId(value)}
          >
            {Array.isArray(groupData) &&
              groupData.map((group) => (
                <Select.Option key={group.groupId} value={group.groupId}>
                  {group.groupsName}
                </Select.Option>
              ))}

          </Select>
        </Form.Item> */}
        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type="primary" style={{ borderRadius: 0 }}>
                Cancel
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
                Register
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>

  );
}
export default AddUser;