//region Layouts
import PublicLayout from './layouts/Public/Public';
import AdminLayout from "./layouts/Admin/Admin";
//endregion

//region Default Routes
import PageUserSettings from "./pages/UserSettings/UserSettings";

//endregion


//region Public Routes
import PageSignIn from './pages/signIn/SignIn';
import AddUser from './pages/UserSettings/AddUser';
import UpdateUser from './pages/UserSettings/UpdateUser';
import PasswordChange from './pages/home/Account/PasswordChange';
import AddCustomer from './pages/home/CompanyManagement/AddCustomer';
import CustomerManagement from './pages/home/CompanyManagement/CustomerManagement';
import CompanyManagement from './pages/home/CompanyManagement/CompanyManagement';
import UpdateCompany from './pages/home/CompanyManagement/UpdateCompany';
import ForgotPassword from './pages/signIn/ForgotPassword';
import EmailChange from './pages/home/Account/EmailChange';
import LicenseManagement from './pages/home/LicenseManagement/LicenseManagement';
import AddLicense from './pages/home/LicenseManagement/AddLicense';
import UpdateLicense from './pages/home/LicenseManagement/UpdateLicense';
import DeviceLicense from './pages/home/DeviceLicense/DeviceLicense';
import AddDevice from './pages/home/DeviceLicense/AddDevice';
import UpdateDevice from './pages/home/DeviceLicense/UpdateDevice';
import TransferLicense from './pages/home/DeviceLicense/TransferLicense';
import ApplicationManagement from './pages/home/ApplicationManagement/ApplicationManagement';
import AddEditApplication from './pages/home/ApplicationManagement/AddEditApplication';

//endregion

export interface IRoute {
  path: string;
  component: any;
  exact?: boolean;
  children?: IRoute[];
}

export const defaultRoutes: IRoute[] = [  
  {
    path: '/user-settings',
    component: PageUserSettings
  }, 
  {
    path: '/add-user',
    component: AddUser
  }, 
  {
    path: '/update-user/:userId',
    component: UpdateUser
  }, 
  {
    path: '/update-company/:companyId',
    component: UpdateCompany
  },
  {
    path: '/reset-password',
    component: PasswordChange
  },
  {
    path: '/change-email',
    component: EmailChange
  },
  {
    path: '/customer-management',
    component: CustomerManagement
  },
  {
    path: '/add-customer',
    component: AddCustomer
  },
  {
    path: '/company-management',
    component: CompanyManagement
  },
  {
    path: '/licence-management',
    component: LicenseManagement
  },
  {
    path: '/add-licence',
    component: AddLicense
  },
  {
    path: '/update-licence/:licenseId',
    component: UpdateLicense
  },
  {
    path: '/device-licence/:licenseId',
    component: DeviceLicense
  },
  {
    path: '/add-device',
    component: AddDevice
  },
  
  {
    path: '/update-device/:deviceId',
    component: UpdateDevice
  },
  {
    path: '/transfer-licence/:deviceId',
    component: TransferLicense
  },
  {
    path: '/application-management',
    component: ApplicationManagement
  },
  {
    path: '/addEditApp/:applicationId',
    component: AddEditApplication
  }
];

export const publicRoutes: IRoute[] = [
  {
    path: '/sign-in',
    component: PageSignIn
  },
  {
    path: '/forgot-password',
    component: ForgotPassword
  }
];

export const routes: IRoute[] = [
  {
    path: '/admin',
    component: AdminLayout,
    children: defaultRoutes
  },
  {
    path: '/public',
    component: PublicLayout,
    children: publicRoutes
  },
];
