import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IPageProps } from "../../../interfaces/page-data";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { Button, Card, Col, Form, Input, Modal, Popconfirm, Row, Space, Tooltip } from "antd";
import { UserOutlined, DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { IApplicationAndFeatures } from '../../../interfaces/IApplicationData';
import { IFeatureData } from '../../../interfaces/IFeatureData';
import AltisDataGrid, { IAltisDataGridColumns } from '../../../AltisComponent/AltisDataGrid';
import { setPageData } from '../../../redux/pages/actions';
import { features } from 'process';

// export interface PageData{
//   featureName: string,
//   features: any[]
// }

const AddEditApplication: React.FunctionComponent<IPageProps> = (props) => {

  const { applicationId } = useParams<any>();
  const [applicationData, setApplicationData] = useState<IApplicationAndFeatures>({application:{},features:[]});
  const [updateDataIndex, setUpdateDataIndex] = useState<number>(-1);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!isNaN(applicationId) && applicationId != 0) {
      GetApplicationData(applicationId);
    }
  }, [applicationId]);

  const DeleteFeature = (index) => {
    console.log("Index:", index);
    console.log("Application Data:", applicationData);

    const updatedFeatures = applicationData.features.map((feature, idx) => 
        idx === index ? { ...feature, isDeleted: true } : feature
    );

    setApplicationData(prevData => ({
        ...prevData,
        features: updatedFeatures
    }));
    
    console.log("Updated Application Data: ", {
        ...applicationData,
        features: updatedFeatures
    });
  };

  const columns: IAltisDataGridColumns[] = [
    {
      title: 'Feature Name',
      key: 'featureName',
      placeHolder: 'Feature Name',
      inputType: 'input',
      align: 'left',
    },
    {
      title: 'Operations',
      key: 'operations',
      placeHolder: 'featureName',
      inputType: 'operations',

      render: (order, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Space size={5}>
            <Popconfirm
              title={
                <div>
                  Are you sure you want to delete this feature? <br />
                </div>
              }
              onConfirm={() => {
                DeleteFeature(index);
              }}
              okText='Yes'
              cancelText='No'>
                <Button
                  // shape='circle'
                  size='middle'
                  icon={<DeleteTwoTone />}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}></Button>
            </Popconfirm>
            <Tooltip title='Update'>
              <Button
                size='middle'
                icon={<EditTwoTone />}
                onClick={() => { setUpdateDataIndex(index) }}
              />
            </Tooltip>
          </Space>
        </div>
      ),
    },
  ]

  const updateFeature = () => {
    form.validateFields()
      .then(values => {
        // Yeni özellikler dizisini oluşturun
        const newFeatures = applicationData.features.map((feature, index) => {
          if (index === updateDataIndex && !feature.isDeleted) {
            return { ...feature, featureName: values.updatedFeatureName };
          }
          return feature;
        });
  
        const newData: IApplicationAndFeatures = {
          ...applicationData,
          features: newFeatures
        };
  
        console.log(newData);
  
        // State'i güncelleyin
        setApplicationData(newData);
  
        // Modalı kapatın
        setUpdateDataIndex(-1);
  
        form.resetFields();
      })
      .catch(errorInfo => {
        toast.error("Validation Error");
        console.log('Validation failed:', errorInfo);
      });
  };
  

  const GetApplicationData = (applicationId: number) => {
    console.log("Get Data")
    HTTPService.GET(`/Application/GetApplication?applicationId=${applicationId}`)
      .then((res) => {
        if (!res.data) {
          return;
        }
        let tmp: IApplicationAndFeatures = JSON.parse(res.data);
        setApplicationData(tmp);
      })
      .catch((error) => {
        console.error("Error fetching application data:", error);
      });
  };


  const featureFilter = (e?) => {
    
     console.log(e);
     var body = {
      applicationId:applicationId,
      featureName: e?.featureName
    };

    HTTPService.POST(`/Feature/FeatureFilter`, body).then((response) => {
      if (!response.data) {
        return;
      }
      if(response.status === 200){
        let tmp: IFeatureData[] = JSON.parse(response.data);
        var app :IApplicationAndFeatures= {
          application : applicationData.application,
          features : tmp
        }
        setApplicationData(app)
      }
    });
  };
  const handleAddFeature = (form: any) => {
    if (form.featureName != null) {
      const newFeatureData: IApplicationAndFeatures = {
        application: applicationData.application,
        features: [...applicationData.features, { featureName: form.featureName, applicationId: applicationId,featureId: 0 }]
      };
      setApplicationData(newFeatureData)
    }

  };

  const handleCancel = () => {
    window.location.replace('/admin/application-management/');
  };

  const onFinish = (values: any) => {

    let url = "/Application/ApplicationRegister";
    if(applicationId !=  0)
    {
      url = "/Application/UpdateApplication";
    }

    console.log(url)
    console.log(applicationData)

    HTTPService.POST(url, applicationData)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Application Add Successfully');
          window.location.replace('/admin/application-management');
        } else {
          toast.error(response.data);
        }
      })
      .catch((error) => {
        toast.error('Application register failed')
        console.error('Application failed: ', error);
      });
  };

  const changeData = (key, value) =>{
    console.log(applicationData)
    applicationData.application[key] = value;

    var obj : IApplicationAndFeatures = {
      application : applicationData.application,
      features : applicationData.features
    }

    setApplicationData(obj);
  }

  return (
    <Card style={{ padding: '50px' }}>
      <h3>{applicationId !== "0" ? "Update Application" : "Register Application"}</h3>
      <Form layout='vertical' onFinish={onFinish}
      
        fields={[
           {
            name: ['applicationName'],
            value: applicationData?.application?.applicationName,
          },
           {
            name: ['applicationExplanation'],
             value: applicationData?.application?.applicationExplanation,
          }
         ] }
        >
        <Form.Item
          label="Application Name"
          name='applicationName'
          rules={[
            {
              required: true,
              message: 'Please input your Application Name!',
              whitespace: true,
            },
          ]}
        >
          <Input
            value={applicationData?.application?.applicationName}
            prefix={<UserOutlined />}
            placeholder='Application Name'
            style={{ borderRadius: 0 }}
            onChange={(target) => changeData("applicationName",target.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Application Explanation"
          rules={[
            {
              required:true,
              message: 'Please input your Application Explanation!',
              whitespace: true,
            },
          ]}
        >
          <Input
            value={applicationData?.application?.applicationExplanation}
            prefix={<UserOutlined />}
            placeholder='Application Explanation'
            style={{ borderRadius: 0 }}
            onChange={(target) => changeData("applicationExplanation",target.target.value)}
          />
        </Form.Item>
        <Form onFinish={handleAddFeature}>
          <Form.Item
            name='featureAdd'
            label="Feature List"
            style={{ marginBottom: '16px', marginTop: '10px' }}
          >
            <div className='col-md-4 mb-3'>
              <Button
                htmlType='submit'
                type='primary'
                shape='round'
                style={{ height: 40, borderRadius: '5px 30px 30px 5px', marginBottom: -5, marginLeft: -10 }}
                size='middle' >
                Add New Feature
              </Button>
            </div>
          </Form.Item>

          <Form.Item name='featureName' >
            <Input
              type="text"
              placeholder="Add new feature"
              style={{ width: 'calc(100% , 40px)', borderRadius: 0, marginRight: '10px' }}
            />
          </Form.Item>
        </Form>
        <div className='row'>
            {applicationData?.features != null ? (
                <div className='col-md-12'>
                    <AltisDataGrid
                      data={applicationData?.features.filter(row => row.isDeleted !== true)}
                      total={applicationData?.features.filter(row => row.isDeleted !== true)?.length}
                      columns={columns}
                      onChange={featureFilter}
                    />
                </div>
            ) : (
                <></>
            )}
        </div>

        <Form.Item>
          <Row gutter={[8, 8]} justify='end' align='middle'>
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type='primary' style={{ borderRadius: 0 }}>
                Cancel
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType='submit' type='primary' style={{ borderRadius: 0 }}>
                {applicationId !== "0" ? 'Update' : 'Register'}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>

      <Modal
        title="Update Feature"
        visible={updateDataIndex != -1}
        onCancel={() => setUpdateDataIndex(-1)}
        onOk={() => { updateFeature() }} >
        <Form 
          form={form}
          layout="vertical"
          fields={[
            {
              name: ['updatedFeatureName'],
              value: applicationData?.features?.filter(row => row.isDeleted != true)[updateDataIndex]?.featureName,
            }
          ]
        }
          >
          <Form.Item
            name="updatedFeatureName"
            label="Updated Feature Name"
            rules={[
              {
                required: true,
                message: 'Please enter updated feature name!',
              }
            ]}
          >
            <Input
              type="text"
              placeholder="Enter updated feature name"
            />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};


export default AddEditApplication;