import React, { useState } from 'react';
import { Alert, Button, Form, Input, Space } from 'antd';
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import HTTPService from "../../utils/makerequest";
import toast from 'react-hot-toast';


const SingInForm: React.FC<any> = props => {
    const [visible, setVisible] = useState(false);

    const handleClose = () => {
        setVisible(false);
    };

    const login = (e: any) => {
        let user = { email: e.email, password: e.password };
        HTTPService.LOGIN(`/Login/Login?email=${user.email}&pass=${user.password}`)
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    console.log(response)
                    window.location.replace('/admin/licence-management/');
                }
                else {
                    toast.error("Username or password is incorrect");
                }
            })
            .catch((response) => {
                toast.error("Bir Hata Oluştu");
                setVisible(true);
                console.log(response.data)
                return;
            });
    }

    return (
        <Form style={{ width: '100%' }} onFinish={login}>
            {visible && (<Space direction="vertical" style={{ width: '100%', marginBottom: '10px' }}>
                <Alert message="email or password wrong!" type="error" closable afterClose={handleClose} />
            </Space>
            )}
            <Form.Item name='email'
                rules={[{ required: true, message: 'Please input your email!', whitespace: true }]}>
                <Input
                    prefix={<UserOutlined />}
                    placeholder='Email'
                    style={{ borderRadius: 0 }}
                />
            </Form.Item>
            <Form.Item name='password'
                rules={[{ required: true, message: 'Please input your password!' }]}>
                <Input.Password
                    prefix={<LockOutlined />}
                    placeholder='Password'
                    style={{ borderRadius: 0 }} />
            </Form.Item>
            <Form.Item>
                <div className='span d-flex flex-column align-items-center'>
                    <span />
                    <Button htmlType="submit" type="primary" style={{ width: '60%', marginBottom: '15px', borderRadius: 0 }}>
                        Sign In
                    </Button>
                    <Button htmlType="submit" type="primary" style={{ width: '60%', borderRadius: 0 }}
                        onClick={() => {
                            console.log('eeee')
                            window.location.replace('/public/forgot-password/');
                        }
                        }>
                        Forgot Password?
                    </Button>
                </div>
            </Form.Item>

        </Form>
    );
};
export default SingInForm;